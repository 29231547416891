import axios from 'axios';

const api = axios.create({
  baseURL: 'https://nki0i62v9a.execute-api.us-west-2.amazonaws.com/d41'
});

export async function fetchOrder(client, orderid) {
  const data = await api.post('/getorder', {
    client : client,
    orderid : orderid })
    .then((resp) => { return resp; })
    .catch((err) => { 
        let d = err.response.data;
        if (typeof d === 'string' || d instanceof String) {
            return d;
        }
        return d.message; })
  return data;
}
