import './App.css';

import React from 'react';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';

var query_string = window.location.search.replace(/^\?/gm, '');

var client = "";
for(let s of query_string.split("&")) {
    var s_split = s.split("=");
    var name = s_split[0];
    if (name === "client") {
        client = s_split[1];
    }
}

var img_path = "https://titantools-public-static-assets.s3.us-west-2.amazonaws.com/" + client + ".png";

var width_percent = "90%";
if (window.screen.width >= 1280) { 
    width_percent = "30%";
}
const divStyle = {
    margin :'0 auto',
    width: width_percent,
}

const App = () => {
    return (
        <div className="search" style={divStyle}>
            <br/>
            <br/>
            <div style={{
                textAlign: 'center'
                }}>
                <img src={img_path} alt="logo" />
                <br/>
                <br/>
                <h3>  Track Your Order </h3>
            </div>
            <br/>
            <SearchBox/>
            <br/>
            <SearchResult/>
        </div>
    );
}

export default App
