import React from 'react';
import { view } from '@risingstack/react-easy-state';
import SearchBar from 'material-ui-search-bar';
import LinearProgress from '@material-ui/core/LinearProgress';
import appStore from './appStore';


var query_string = window.location.search.replace(/^\?/gm, '');

var client = "";
var orderid = "";
for(let s of query_string.split("&")) {
    var s_split = s.split("=");
    var name = s_split[0];
    if (name === "client") {
        client = s_split[1];
    }
    if (name === "orderid") {
        orderid = s_split[1];
    }
}

// this is re-rendered whenever the relevant parts of the used data stores change
const SearchBox = () => (
  <div className="searchbar">
    <SearchBar
      onRequestSearch={(event) => appStore.search(client, event)}
      value={orderid}
      autoFocus
    />
    {appStore.isLoading && <LinearProgress />}
  </div>
);

appStore.search(client, orderid);

export default view(SearchBox);