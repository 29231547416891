import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  makeStyles,
} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import { view } from '@risingstack/react-easy-state';
import appStore from './appStore';
import FlightIcon from '@material-ui/icons/Flight';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';

const RedTextTypography = withStyles({
  root: {
    color: "#FF0000"
  }
})(Typography);


const OverallStepStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#eaeaf0',
    padding: 8,
    borderRadius: '50%'
  },
  active: {
    color: '#3f51b5',
  },
  completed: {
    color: '#3f51b5',
  },
}));



const OverallStepIcon = (props) => {
  const classes = OverallStepStyle();
  const { active, completed } = props;

  var stepIcons = {};
  var idx = 1;
  for (let step of appStore.searchResult.overallSteps) {
    if (step.type === "freight") {
      stepIcons[idx] = <FlightIcon />;
    } else if (step.type === "parcel") {
      stepIcons[idx] = <LocalShippingIcon />;
    } else {
      stepIcons[idx] = <MailIcon />;
    }
    idx += 1;
  }
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {stepIcons[String(props.icon)]}
    </div>
  );
};

const SearchResult = () => (

  <div className="searchresult">
      { appStore.searchFound && <div className="seachResult">
          <center><h4>Overall Status</h4></center>
          <Stepper activeStep={appStore.searchResult.activeOverallStep} orientation="horizontal" alternativeLabel>
              { appStore.searchResult.overallSteps.map((info, index) => (
                  <Step key={index}>
                      <StepLabel className={appStore.searchResult.useStyles.stepLabel} StepIconComponent={OverallStepIcon} >{info.carrier}
                       <br/> 
                       <Typography variant="caption">{info.trackingNumber}
                       </Typography>
                      </StepLabel>
                  </Step>
              ))}
          </Stepper>
          <center><h4>Detailed Events</h4></center>
          <Stepper activeStep={appStore.searchResult.activeStep} orientation="vertical">
              { appStore.searchResult.steps.map((info, index) => (
                  <Step key={index}>
                      <StepLabel className={appStore.searchResult.useStyles.stepLabel}>{info.location}
                       <br/> 
                       <Typography variant="caption">{info.description} {info.time}
                       </Typography>
                      </StepLabel>
                      <StepContent>
                      </StepContent>
                  </Step>
              ))}
          </Stepper>
          {appStore.searchResult.activeStep === appStore.searchResult.steps.length && (
              <Paper square elevation={0} className={appStore.searchResult.useStyles.resetContainer}>
                  <Typography>Your order has been delivered!</Typography>
              </Paper>
          )}
      </div>}

      { appStore.searchError && 
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <RedTextTypography variant="h6">
              { appStore.searchError  }
            </RedTextTypography>
          </div>
      }
  </div>
);

export default view(SearchResult);
