import { store } from '@risingstack/react-easy-state';
import { makeStyles } from '@material-ui/core/styles';
import * as api from './api';

var timeDisplayOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

function utcTimeToLocal(s) {
  if (s.includes("+")) {
    return new Date(s).toLocaleString(undefined, timeDisplayOptions)
  }
  return new Date(s + "+0000").toLocaleString(undefined, timeDisplayOptions)
}

// use 'appStore' instead of 'this' in the store methods to make them passable as callbacks
const appStore = store({
  async search(client, orderid) {
    console.log("searching", client, orderid);
    appStore.isLoading = true;
    appStore.searchFound = false;
    appStore.searchError = null;

    // do some http call here to get result
    let order = await api.fetchOrder(client, orderid);
    //console.log(order);
    appStore.isLoading = false;
    if (typeof order === 'string' || order instanceof String) {
        appStore.searchFound = false;
        appStore.searchError = order;
        return;
    }
    order = order.data;
    //console.log(order);
    var tracking = order.tracking.reverse();
    var events = order.events.reverse();
    var steps = [];
    var overallSteps = [];
    for (let event of events) {
      steps.push({'location':event.location,
          'description':event.description,
          'time':event.time});
    }
    var activeOverallStep = 0;
    for (let track of tracking) {
      overallSteps.push({'carrier':track.carrier.toUpperCase(),
          'trackingNumber':track.tracking_number,
          'type':track.type});
      if (track.complete) {
        activeOverallStep += 1;
      }
    }
    overallSteps.push({
      'carrier' : "DELIVERED",
      'trackingNumber':"",
      'type': "delivered"
    });
    appStore.searchResult.activeStep = steps.length - 1;
    appStore.searchResult.steps = steps;
    appStore.searchResult.overallSteps = overallSteps;
    appStore.searchResult.activeOverallStep = activeOverallStep;
    appStore.searchFound = true;
  },
  searchResult : {
    useStyles : makeStyles((theme) => ({
      stepLabel: {
          '& > *': {
                fontWeight: 600,
          }
      },
      stepContent: {
          color: 'gray',
          fontSize: 12
      }
    })),
    activeStep : 0,
    steps : ["empty"],
    updateTime : "none",
    statusDetails : null,
    locationDetails : null,
  }
});

export default appStore;